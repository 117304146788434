<template>
  <painel-component :items="itemsOne">
    <!-- Modal de Consignado -->
    <div v-if="openModalConsigned" class="modal-overlay" @click.self="modalConsigned">
      <div class="modal-content">
        <div class="modal-header">
          <h4></h4>
          <div role="button" @click="modalConsigned" class="btn-close"></div>
        </div>
        <div class="modal-body">
          <!-- <MultiSelect
            v-model="formData.consigned"
            placeholder="Selecionar Consignado"
            :options="formattedConsignedList"
            @change="selectedConsigned"
            class="custom-multiselect"
          /> -->
          <row-component>
            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div class="form-check form-check-inline">
                <input v-model="selectedDoc" type="radio" value="1" name="docs" id="type-one" />
                <label class="form-check-label" for="type-one">CPF</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="selectedDoc" type="radio" value="2" name="docs" id="type-two" checked />
                <label class="form-check-label" for="type-two">CNPJ</label>
              </div>
            </row-col-component>
          </row-component>

          <form @submit.prevent="loadConsigned">
            <!-- Adicionando prevent para evitar recarregar a página -->
            <row-component>
              <col-component class-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formDataConsigned.name" type="text" />
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Apelido:</label>
                <input-component v-model="formDataConsigned.surname" type="text" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                v-if="selectedDoc === '1'"
              >
                <label>CPF:</label>
                <input-component v-model="formDataConsigned.cpf" type="text" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                v-if="selectedDoc === '2'"
              >
                <label>CNPJ:</label>
                <input-component v-model="formDataConsigned.cnpj" type="text" />
              </col-component>

              <col-component class-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                <button-submit class="me-1" type="submit" icon="search" />
                <!-- Tipo submit para indicar que é um botão de envio -->
              </col-component>
            </row-component>
          </form>

          <div class="mt-4">
            <row-component>
              <col-component
                class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                v-for="(data, index) in consignedList"
                :key="index"
              >
                <div class="d-flex align-items-center bg-body-secondary p-2 position-relative mt-2">
                  <div class="h-100 avatar__image__div">
                    <img class="avatar__image" v-if="data.photo" alt="" />
                    <img class="avatar__image" v-else src="@/assets/avatar.png" alt="" />
                  </div>
                  <div class="ms-3 w-75">
                    <p class="mb-1 text-truncate">{{ data.name }}</p>
                    <p class="mb-1">
                      {{ data.cnpj }} <br />
                      {{ data.cpf }}
                    </p>
                  </div>
                  <div class="position-absolute end-0 me-1 me-lg-3">
                    <button-submit icon="icon plus" @click="selectConsigned(data)" />
                  </div>
                </div>
              </col-component>
            </row-component>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>

    <form @submit.prevent="loadItems">
      <row-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Código:</label>
          <input-component placeholder="Código" v-model="formData.code" />
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 consignado-col">
          <label>Consignado:</label>
          <div class="input-container">
            <input-component placeholder="Selecionar Consignado" :value="consignedName" :disabled="true" />
            <span class="icon-button" @click="modalConsigned">
              <i class="icon search"></i>
            </span>
          </div>
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" placeholder="Status" :options="selectStatusConsigned" />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="me-1" @click="submit" icon="search" name="Pesquisar" />
          <button-route v-if="buttonCheck.create" :items="itemsTwo" />
        </col-component>
      </row-component>
    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
              <tr>
                <th scope="col" style="width: 25%">Código</th>
                <th scope="col" style="width: 35%">Consignado</th>
                <th scope="col" style="width: 30%">Status</th>
                <th scope="col" style="width: 35%" v-if="buttonCheck.edit">Ações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="data in object.data" :key="data" class="position-relative">
                <td>{{ data.code }}</td>
                <td>
                  {{ data.name }}
                </td>
                <td>{{ statusString(data.status) }}</td>
                <td>
                  <button-edit :id="data.id" route="acquisition-edit" v-if="buttonCheck.edit" />
                  <button-delete :id="data.id" v-if="buttonCheck.delete" @click="destroyItem(data)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button-pagination :pagination="object" :offset="3" @paginate="loadItems" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { useToast } from 'vue-toastification';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonRoute from '@/components/buttons/ButtonRoute.vue';
import swal from 'sweetalert';
const toast = useToast();

export default {
  name: 'AcquisitionSearch',

  components: {
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent,
    ButtonSubmit,
    ButtonEdit,
    ButtonDelete,
    ButtonRoute
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Aquisição');
    this.loadItems(1);
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        title: 'Pesquisar',
        icon: 'list alternate'
      },

      itemsTwo: {
        route: '/acquisition/create',
        icon: 'plus',
        name: 'Adicionar'
      },

      buttonConfirm: {
        title: 'Pesquisar',
        icon: 'list alternate'
      },

      ButtonCancel: {
        icon: 'plus',
        name: 'Adicionar'
      },

      buttonCheck: {
        create: false,
        edit: false,
        delete: false
      },

      formData: {
        code: '',
        consigned: null,
        status: ''
      },

      formDataConsigned: {
        name: '',
        surname: '',
        cpf: '',
        cnpj: ''
      },

      items: [],

      openModalConsigned: false,

      consignedList: [],

      viewPage: false,
      selectedDoc: '2'
    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    modalConsigned() {
      this.openModalConsigned = !this.openModalConsigned;
      this.loadConsigned();
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadAcquisitions', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    loadConsigned() {
      const params = {
        type: '',
        name: this.formDataConsigned.name,
        surname: this.formDataConsigned.surname,
        cnpj: this.formDataConsigned.cnpj,
        cpf: this.formDataConsigned.cpf
      };

      this.$store
        .dispatch('loadConsigneds', params)
        .then(response => {
          if (Array.isArray(response) && response.length > 0) {
            const consignedsData = response[0].data; // Altere isso se a estrutura for diferente
            this.consignedList = consignedsData;
          } else {
            this.consignedList = [];
          }
        })
        .catch(error => {
          console.error('Erro ao carregar consignados: ', error);
        });
    },

    selectedConsigned(selectedValue) {
      this.formData.consigned = selectedValue;
      this.openModalConsigned = !this.openModalConsigned;
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 22)
        .then(r => {
          if (r.indexOf('acquisition-search') !== -1) {
            this.viewPage = true;

            if (r.indexOf('acquisition-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.indexOf('acquisition-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.indexOf('acquisition-delete') !== -1) {
              this.buttonCheck.delete = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    statusString(value) {
      if (parseInt(value) === 1) return 'Em cadastro';
      if (parseInt(value) === 2) return 'Em consignação';
      if (parseInt(value) === 3) return 'Finalizado';
      return 'Cancelado';
    },

    destroyItem(data) {
      if (data.status === '1') {
        swal({
          title: 'Atenção!',
          text: `Deseja realmente deletar?`,
          closeOnClickOutside: false,
          buttons: {
            cancel: {
              text: 'Não',
              visible: true,
              closeModal: true
            },
            confirm: 'Sim'
          }
        }).then(result => {
          if (result) {
            this.isLoading = true;
            this.$store
              .dispatch('destroyAcquisition', data.id)
              .then(() => {
                toast.success('Deletado com sucesso', { timeout: 2000 });
                this.loadItems();
              })
              .catch(() => {
                toast.error(`Não foi possivel deletar  ${data.code} `, {
                  timeout: 2000
                });
              });
          }
        });
      } else {
        toast.error(`Não é possível deletar  ${data.code} `, {
          timeout: 2000
        });
      }
    },

    selectConsigned(consigned) {
      this.formData.consigned = consigned.id; // Armazena o ID do consignado selecionado
      this.openModalConsigned = false; // Fecha o modal
    }
  },

  computed: {
    selectStatusConsigned() {
      return this.$store.state.var.selectConsigned;
    },

    object() {
      return this.$store.state.acquisition.items;
    },

    formattedConsignedList() {
      return this.consignedList.map(consigned => ({
        label: consigned.name, // O que será exibido no MultiSelect
        value: consigned.id // O que será armazenado no v-model
      }));
    },

    consignedName() {
      const consignado = this.object.data.find(item => item.id === this.formData.consigned);
      return consignado ? consignado.name : '';
    },

    params() {
      return {
        code: this.formData.code,
        status: this.formData.status,
        consigned_id: this.formData.consigned
      };
    }
  }
};
</script>

<style scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-container input-component {
  width: 100%;
}

.icon-button {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px 3px 3px 9px;
  background-color: #000;
}

.icon-button .icon {
  font-size: 1.5em;
  color: #fff;
}

/* Estilos do modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 1200px;
  max-width: 90%;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.btn-cancel {
  background-color: gray;
  border: 1px solid gray;
}

.btn-cancel:hover {
  background-color: rgb(112, 112, 112) !important;
  border: 1px solid rgb(112, 112, 112) !important;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-card {
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  padding: 10px;
  border-radius: 5px;
}

.user-info {
  flex-grow: 1;
}

.add-button {
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.avatar__image__div {
  width: 10%;
}

.form-check-input:checked {
  background-color: var(--primary-color-hexa);
  border-color: var(--primary-color-hexa);
}

.form-check-input:focus,
.form-check-input:focus-visible {
  box-shadow: none !important;
}
</style>
