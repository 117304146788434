<template>
  <div
    class="modal-w d-grid position-fixed top-0 start-0 h-100 w-100"
    v-if="modal || modal_print || product_shop.modal"
  >
    <div class="modal-p position-relative p-4 pt-5" v-if="modal">
      <div @click="modal = false" role="button" class="btn-close position-absolute"></div>

      <row-component>
        <col-component
          v-for="(data, index) in photos"
          :key="index"
          class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 px-3"
        >
          <div class="card rounded-0">
            <img class="img-fluid" v-if="data.photo" :src="data.photo" alt="" />
            <div class="btn-group" role="group" aria-label="Basic example">
              <button-submit
                @click="updateProductPhotoCover(data.id)"
                icon="check"
                name="Selecionar"
              />
            </div>
          </div>
        </col-component>
      </row-component>
    </div>

    <div
      class="modal-p position-relative p-4 pt-5"
      v-if="product_shop.modal"
      style="min-height: fit-content; width: 60%"
    >
      <div @click="closeProduct(false)" role="button" class="btn-close position-absolute"></div>

      <error-component :errors="product_shop.errors" v-if="product_shop.showError" />

      <row-component>
        <!-- <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 px-3"
                               :class="{'formError' : product_shop.errors.amount}">
                    <label>Quantidade:</label>
                    <input-component type="number" v-model="product_shop.formData.amount"/>
                </col-component> -->

        <col-component
          class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 px-3"
          :class="{ formError: product_shop.errors.price_sale }"
        >
          <label>Preço Venda:</label>
          <input-component v-money="MONEY" v-model="product_shop.formData.price_sale" />
        </col-component>

        <col-component
          class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 px-3"
          :class="{ formError: product_shop.errors.price_promotional }"
        >
          <label>Preço Promocional:</label>
          <input-component v-money="MONEY" v-model="product_shop.formData.price_promotional" />
        </col-component>

        <col-component
          class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 px-3"
          :class="{ formError: product_shop.errors.discount }"
        >
          <label>Desconto (%):</label>
          <input-component
            @keyup="limitarInput(product_shop.formData.discount)"
            v-model="product_shop.formData.discount"
          />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 px-3">
          <label>Categoria:</label>
          <MultiSelect
            v-model="product_shop.formData.categorie"
            :options="selectProductCategoryFunction"
          />
        </col-component>

        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 px-3">
          <label>Status:</label>
          <MultiSelect v-model="product_shop.formData.status" :options="selectActiveInativeBW" />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 px-3">
          <button-submit @click="submitProductShop" icon="send" name="Enviar" />
        </col-component>
      </row-component>
    </div>
  </div>

  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError" />

    <div class="tabs effect-3" v-if="viewPage">
      <input type="radio" id="tab-1" name="tab-effect-3" checked />
      <span>
        <i class="info circle icon"></i>
        <span>Informações</span>
      </span>

      <input type="radio" id="tab-2" name="tab-effect-3" />
      <span>
        <i class="camera icon"></i>
        <span>Foto</span>
      </span>

      <div class="line ease"></div>
      <div class="tab-content">
        <section id="tab-item-1">
          <span
            class="position-absolute top-0 rounded-0 badge bg-black"
            v-if="parseInt(formData.bw) === 2"
          >
            Sincronizado BW
          </span>

          <div class="clearfix mb-1">
            <button-submit
              title="Imprimir Etiqueta"
              class="float-end"
              icon="print"
              @click="openPrintTag"
            />
            <button-submit
              title="Enviar para e-commerce"
              class="float-end me-2"
              @click="closeProduct(true)"
              icon="send"
            />
            <button-submit
              v-if="parseInt(formData.bw) === 2"
              title="Remover do e-commerce"
              class="float-end me-2"
              @click="destroyProductShop"
              icon="trash"
            />
          </div>

          <form>
            <row-component>
              <col-component
                class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.name }"
              >
                <label>Nome:</label>
                <input-component v-model="formData.name" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.name }"
              >
                <label>Referência:</label>
                <input-component disabled v-model="formData.code" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.status }"
              >
                <label>Status:</label>
                <MultiSelect v-model="formData.status" :options="selectActiveInative" />
              </col-component>
            </row-component>

            <row-component>
              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.minimum_stock_store }"
              >
                <label>Estoque mínimo loja:</label>
                <input-component v-model="formData.minimum_stock_store" type="number" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.initial_inventory_store }"
              >
                <label>Estoque loja:</label>
                <input-component v-model="formData.initial_inventory_store" type="number" />
              </col-component>

              <!-- <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.initial_inventory_shop}">
                                <label>Estoque e-commerce:</label>
                                <input-component disabled v-model="formData.initial_inventory_shop" type="number"/>
                            </col-component> -->

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.kilo }"
              >
                <label>Peso:</label>
                <div class="position-relative">
                  <p
                    class="position-absolute top-50 start-0 translate-middle-y"
                    style="margin-left: 10px"
                  >
                    {{ formData.kilo }}
                  </p>
                  <input-component
                    autocomplete="off"
                    type="number"
                    id="kilos"
                    @keyup="funcOne"
                    style="color: #ffffff !important"
                  />
                </div>
              </col-component>

              <!--                            <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                <label>Peso (GR)</label>
                                {{formData.kilo}}
                                <input-component @change="maxLength(formData.kilo)"  v-model="formData.kilo"/>
                            </col-component>-->

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.price }"
              >
                <label>Valor:</label>
                <input-component v-model="formData.price" v-money="MONEY" />
              </col-component>
            </row-component>

            <row-component>
              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.category_id }"
              >
                <label>Categoria:</label>
                <MultiSelect v-model="formData.category_id" :options="selectCategory" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.subcategory_id }"
              >
                <label>Subcategoria:</label>
                <MultiSelect v-model="formData.subcategory_id" :options="selectSubcategory" />
              </col-component>

              <col-component
                class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.height }"
              >
                <label>Altura (cm):</label>
                <input-component v-money="centimeters" v-model="formData.height" />
              </col-component>

              <col-component
                class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.width }"
              >
                <label>Largura (cm):</label>
                <input-component v-money="centimeters" v-model="formData.width" />
              </col-component>

              <col-component
                class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.length }"
              >
                <label>Comprimento (cm):</label>
                <input-component v-money="centimeters" v-model="formData.length" />
              </col-component>
            </row-component>

            <row-component>
              <col-component
                class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.sector }"
              >
                <label>Setor:</label>
                <input-component v-model="formData.sector" />
              </col-component>

              <col-component
                class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.column }"
              >
                <label>Coluna:</label>
                <input-component v-model="formData.column" type="number" />
              </col-component>

              <col-component
                class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.shelf }"
              >
                <label>Prateleira:</label>
                <input-component v-model="formData.shelf" type="number" />
              </col-component>
            </row-component>

            <row-component>
              <col-component
                class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                :class="{ formError: errors.desc }"
              >
                <label>Descrição:</label>
                <text-area v-model="formData.desc" rows="6" />
              </col-component>
            </row-component>

            <row-component>
              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <button-submit
                  :disabled="sendForm"
                  @click="submit"
                  class="w-100"
                  icon="save"
                  name="Salvar"
                />
              </col-component>

              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <button-route class="w-100" :items="itemsTwo" />
              </col-component>
            </row-component>
          </form>
        </section>

        <section id="tab-item-2">
          <div class="clearfix mb-3">
            <button-submit
              @click="openModal"
              name="Selecionar capa"
              v-if="formData.photos.length > 0"
            />

            <label class="btn-label btn" for="files" v-if="!cropper">
              <i class="m-0 icon plus"></i>
              <input id="files" @change="onFileChange" class="d-none" type="file" />
            </label>

            <button-submit
              @click="updateProductPhoto"
              v-else-if="this.photo_id !== ''"
              class="float-end"
              icon="save"
            />
            <button-submit @click="onChange" v-else class="float-end" icon="save" />

            <button-submit
              v-if="cropper"
              @click="cropper = false"
              class="float-end me-2"
              icon="close"
            />

            <button-submit
              v-if="parseInt(formData.bw) === 2"
              @click="syncProductPhoto"
              class="float-end me-2"
              icon="sync"
            />
          </div>

          <div v-if="cropper" style="width: 800px; display: block; margin: 0 auto; height: 800px">
            <cropper ref="cropper" class="me-3" :src="photoCropper" />
          </div>

          <div
            class="alert alert-secondary alert-dismissible fade show"
            role="alert"
            v-if="formData.photos.length === 0"
          >
            <p>Não há nada cadastrado</p>
          </div>

          <div class="draggable" v-else>
            <draggable v-model="formData.photos" @start="drag = true" @end="onEnd" item-key="id">
              <template #item="{ element, index }">
                <col-component
                  class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 px-3"
                >
                  <div
                    class="card rounded-0 border-2"
                    :class="{ 'border-black': parseInt(element.cover) === 2 }"
                  >
                    <img class="img-fluid" v-if="element.photo" :src="element.photo" alt="" />
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <label
                        @click="getPhotoID(element.id)"
                        class="btn-label btn"
                        :for="'files' + index"
                        v-if="!cropper"
                      >
                        <i class="m-0 icon edit"></i>
                        <input
                          :id="'files' + index"
                          @change="onFileChange"
                          class="d-none"
                          type="file"
                        />
                      </label>
                      <button-delete @click="destroyProductPhoto(element.id)" />
                    </div>
                  </div>
                </col-component>
              </template>
            </draggable>
          </div>
        </section>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import TextArea from '@/components/form/TextArea';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import draggable from 'vuedraggable';

import swal from 'sweetalert';
import { VMoney } from 'v-money';
import { MONEY } from '@/configs/constants';
import { useToast } from 'vue-toastification';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { ref } from 'vue';

const toast = useToast();
export default {
  name: 'ProductUpdate',

  components: {
    draggable,
    Cropper,
    ErrorComponent,
    ButtonDelete,
    ButtonRoute,
    ButtonSubmit,
    TextArea,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  directives: {
    money: VMoney
  },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Produto');
    this.$store.dispatch('loadSelectCategory');
    this.$store.dispatch('loadSelectSubcategory');
    this.$store.dispatch('loadSelectProductCategory');
    this.loadItems();
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        title: 'Editar',
        icon: 'edit'
      },

      itemsTwo: {
        route: '/product/search',
        icon: 'reply all',
        name: 'Voltar'
      },

      formData: {
        name: '',
        status: '',
        price: '',
        bw: '',
        code: '',
        minimum_stock_store: '',
        initial_inventory_store: '',
        category_id: '',
        subcategory_id: '',
        kilo: '',
        height: '',
        width: '',
        length: '',
        sector: '',
        shelf: '',
        column: '',
        desc: '',
        photos: [],
        printTag: ''
      },

      MONEY,

      amount: '',

      centimeters: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },

      photo: '',
      photo_id: '',
      photoCropper: '',
      cropper: false,
      imagePreview: '',

      drag: false,

      photos: '',
      modal: false,

      modal_print: false,

      product_shop: {
        modal: false,
        errors: [],
        showError: false,
        formData: {
          product_id: this.id,
          amount: '',
          price_sale: '',
          price_promotional: '0',
          discount: 0,
          categorie: '',
          status: 1
        }
      },

      errors: [],
      viewPage: false,
      sendForm: false,
      showError: false
    };
  },

  methods: {
    loadItems() {
      this.$store
        .dispatch('loadProduct', this.id)
        .then(r => {
          this.formData = r;
        })
        .catch(() => {
          toast.error('Erro desconhecido!', { timeout: 1500 });
        });
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateProduct', this.formData)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            toast.success('Cadastro atualizado com sucesso', { timeout: 1500 });
            window.scroll(0, 0);
          })
          .catch(e => {
            this.showError = true;
            this.sendForm = false;
            window.scroll(0, 0);
            this.errors = e.response.data.errors;
          });
      }
    },

    funcOne(event) {
      let value = event.target.value;
      value = parseInt(0 + value);
      return (this.formData.kilo = this.kiloMask(this.pad(value, 5)));
    },

    kiloMask(v) {
      v = v.replace(/^(\d{3})(\d{1})/, '$1,$2');
      // v = v.replace(/(\d{2})(\d{1,2})$/, "$1.$2") //Coloca um hífen entre o terceiro e o quarto dígitos
      // v = v.replace(/(\d{2})(\d)/, "$1,$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
      return v;
    },

    pad(str, max) {
      str = str.toString();
      str = str.length < max ? this.pad('0' + str, max) : str; // zero à esquerda
      str = str.length > max ? str.substring(0, max) : str; // máximo de caracteres
      return str;
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 11)
        .then(r => {
          if (r.indexOf('product-edit') !== -1) {
            this.viewPage = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    routePrint() {
      const route = this.$router.resolve({
        name: 'product-label',
        params: { id: this.id, amount: this.amount }
      });
      window.open(route.href, '_blank');
      this.modal_print = false;
    },

    openModal() {
      this.$store
        .dispatch('loadProductPhotos', this.id)
        .then(response => {
          this.photos = response;
          this.modal = true;
        })
        .catch(() => {
          toast.error('Erro desconhecido');
        });
    },

    onEnd() {
      this.drag = false;
      let photos = this.formData.photos;
      let formData = { id: this.id, photos_ordem: [] };

      photos.forEach((element, index) => {
        formData.photos_ordem.push({
          id: element.id,
          order: index
        });
      });
      this.$store.dispatch('updateProductPhotoOrder', formData);
    },

    onChange() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob(
        blob => {
          this.photo = blob;
        },
        'image/png',
        '0.95'
      );
      setTimeout(() => {
        this.storeProductPhoto();
        this.$refs.cropper = ref(null);
      }, 500);
    },

    getPhotoID(id) {
      this.photo_id = id;
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.photo = files[0];
      if (files[0].type.indexOf('image') === 0) {
        this.previewImage(files[0]);
      }
      this.cropper = true;
    },

    previewImage(file) {
      let reader = new FileReader();
      reader.onload = e => {
        this.photoCropper = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    storeProductPhoto() {
      let formData = { id: this.id, photo: this.photo };
      this.$store
        .dispatch('storeProductPhoto', formData)
        .then(() => {
          this.errors = [];
          this.showError = false;
          this.cropper = false;
          this.loadItems();
          toast.success('Foto adicionada com sucesso', { timeout: 2000 });
        })
        .catch(e => {
          this.showError = true;
          this.errors = e.response.data.errors;
          window.scroll(0, 0);
        });
    },

    updateProductPhoto() {
      let formData = { id: this.id, photo_id: this.photo_id, photo: this.photo };
      this.$store
        .dispatch('updateProductPhoto', formData)
        .then(() => {
          this.errors = [];
          this.showError = false;
          this.cropper = false;
          this.photo_id = '';
          this.loadItems();
          toast.success('Foto atualizada com sucesso', { timeout: 2000 });
        })
        .catch(e => {
          this.showError = true;
          this.errors = e.response.data.errors;
          window.scroll(0, 0);
        });
    },

    syncProductPhoto() {
      this.$store
        .dispatch('syncProductPhoto', this.id)
        .then(() => {
          this.loadItems();
          toast.success('Fotos sincronizada com sucesso', { timeout: 2000 });
        })
        .catch(e => {
          this.showError = true;
          this.errors = e.response.data.errors;
          window.scroll(0, 0);
        });
    },

    destroyProductPhoto(id) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar esta foto?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        let formData = { id: parseInt(this.id), photo_id: id };
        if (result) {
          this.$store
            .dispatch('destroyProductPhoto', formData)
            .then(() => {
              this.loadItems();
              toast.success('Foto deletado com sucesso', { timeout: 1500 });
            })
            .catch(() => {
              toast.error(`Não foi possível deletar`, { timeout: 1500 });
            });
        }
      });
    },

    updateProductPhotoCover(id) {
      let formData = { id: this.id, photo_id: id };
      this.$store
        .dispatch('updateProductPhotoCover', formData)
        .then(() => {
          this.errors = [];
          this.showError = false;
          this.cropper = false;
          this.modal = false;
          this.loadItems();
          toast.success('Foto atualizada com sucesso', { timeout: 2000 });
        })
        .catch(e => {
          this.showError = true;
          this.errors = e.response.data.errors;
          window.scroll(0, 0);
        });
    },

    limitarInput(value) {
      if (parseInt(value) > 100) this.product_shop.formData.discount = 100;
    },

    closeProduct(bool) {
      this.product_shop.modal = bool;
      this.product_shop.errors = [];
      this.product_shop.showError = false;
      this.product_shop.formData.price_sale = this.formData.price;
      if (bool === false)
        this.product_shop.formData = {
          amount: '',
          price_sale: this.formData.price,
          price_promotional: '0',
          discount: ''
        };
    },

    submitProductShop() {
      this.$store
        .dispatch('storeProductShop', this.product_shop.formData)
        .then(() => {
          this.product_shop.showError = false;
          this.product_shop.errors = [];
          this.product_shop.modal = false;
          toast.success('Cadastro adicionado com sucesso', { timeout: 1500 });
          window.scroll(0, 0);
          this.loadItems();
        })
        .catch(e => {
          this.product_shop.showError = true;
          window.scroll(0, 0);
          this.product_shop.errors = e.response.data.errors;
        });
    },

    changeArray() {
      let arr = [];
      let registros = this.selectProductCategory.original.registros;

      registros.forEach(_el => {
        arr.push({
          label: _el.nome,
          value: _el.id_hierarquia_produto
        });
      });

      return arr;
    },

    changeArrayActiveInative() {
      return [
        { value: 1, label: 'Inativo' },
        { value: 2, label: 'Ativo' }
      ];
    },

    destroyProductShop() {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente remover este item do e-commerce ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store
            .dispatch('destroyProductShop', this.id)
            .then(r => {
              this.formData = r;
              toast.success('Cadastro deletado com sucesso', { timeout: 1500 });
            })
            .catch(() => {
              toast.error(`Não foi possível deletar remover este item do e-commerce`, {
                timeout: 1500
              });
            });
        }
      });
    },

    openPrintTag() {
      window.open(this.formData.printTag, '_blank');
    }
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    selectActiveInativeBW() {
      return this.changeArrayActiveInative();
    },

    selectProductClassification() {
      return this.$store.state.var.selectProductClassification;
    },

    selectCategory() {
      return this.$store.state.var.selectCategory;
    },

    selectSubcategory() {
      return this.$store.state.var.selectSubcategory;
    },

    selectProductCategory() {
      return this.$store.state.var.selectProductCategory;
    },

    selectProductCategoryFunction() {
      return this.changeArray();
    }
  }
};
</script>

<style scoped>
.modal-w {
  z-index: 99;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 80%;
  overflow-y: auto;
  min-height: 800px;
  background-color: #ffffff;
}

.modal-p .btn-close {
  top: 10px;
  right: 10px;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
  width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
  left: 50%;
}

.btn-label {
  float: right;
  color: #ffffff;
  border-radius: 0;
  border-color: #000000;
  background-color: #000000;
}

.btn-label:active,
.btn-label:focus,
.btn-label:hover {
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.draggable > div {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
</style>
