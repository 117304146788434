<template>
  <painel-component :items="itemsOne">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="pt-3 text-uppercase">
        <h5 class="text-dark">
          N° {{ formData.code }} |
          {{ formData.status }}
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status == 1">Aberto</span>
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status == 2">Fechado</span>
        </h5>
        <div class="d-flex flex-column">
          <small>Hora de abertura: {{ formData.dateHourOpen }}</small>
          <small>Hora de fechamento: {{ formData.dateHourClose }}</small>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row align-items-center m-3">
        <button-route class="mx-1" :items="itemsTwo" />
        <button-submit :icon="'print'" @click="openPDF(formData.linkPrint)" />
      </div>
    </div>

    <div class="mt-4 spacing-border"></div>

    <div class="card mt-4 rounded-0 text-uppercase">
      <div class="p-3 d-flex flex-column align-items-center align-items-md-start">
        <h5 class="text-uppercase">Consultora | Cliente</h5>
        <div class="d-flex align-items-center">
          <!-- <img class="profileImg" :src="formData.user_photo" alt="foto_de_perfil"> -->
          <img class="profileImg" src="../../assets/avatar.png" alt="foto_de_perfil" />
          <div>
            <small v-if="formData.user_creater">{{ formData.user_creater }} </small>
            <small v-else>N/I</small>
            |
            <small v-if="formData.name_client">{{ formData.name_client }} </small>
            <small v-else>N/I</small>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4 rounded-0">
      <h5 class="p-3 text-uppercase">Movimentação</h5>
      <div class="table-responsive" style="max-height: 600px; overflow-y: auto">
        <table class="table table-hover">
          <thead class="bg-dark text-white">
            <tr>
              <th scope="col">Tipo</th>
              <th scope="col">Motivo</th>
              <th scope="col">Data - Hora (Aberto)</th>
              <th scope="col">Forma de pagamento</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in formData.entrance_and_exit" :key="item.id">
              <th v-if="item.type == 1"><i class="arrow alternate circle up icon green" />Entrada</th>
              <th v-else><i class="arrow alternate circle down icon red" /> Saída</th>
              <th>{{ item.reason }}</th>
              <td>{{ item.dateHourCreate }}</td>
              <td v-if="item.type_payment == 1">Dinheirooooo</td>
              <td v-if="item.type_payment == 2">PIX</td>
              <td v-if="item.type_payment == 3">Cartão de Crédito</td>
              <td v-if="item.type_payment == 4">Cartão de Débito</td>
              <td>R$ {{ item.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row bg-black text-white py-2 mx-0">
        <div class="col-4 px-2">Total Entrada: {{ formData.totalEntrance }}</div>
        <div class="col-4 px-2">Total Saída: {{ formData.boxTotalMoney }}</div>
        <div class="col-4 px-2">Subtotal: {{ formData.subtotal }}</div>
      </div>
      <div class="row bg-black text-white py-2 mx-0">
        <div class="col-6 px-2">Total Venda: {{ formData.totalSale }}</div>
        <div class="col-6 px-2">Total Caixa: {{ formData.totalEntrance }}</div>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import ButtonRoute from '@/components/buttons/ButtonRoute.vue';

export default {
  name: 'BoxHistoricShow',

  components: {
    PainelComponent,
    ButtonSubmit,
    ButtonRoute
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Histórico Caixa');
    this.checkACL();
    this.loadItem(1);
  },

  props: {
    id: {
      required: true
    }
  },

  data() {
    return {
      itemsOne: {
        title: 'Caixa',
        icon: 'list alternate'
      },

      itemsTwo: {
        route: '/pdv-box-historic/search',
        icon: 'reply all',
        name: ''
      },

      formData: {},

      viewPage: false,

      clients: []
    };
  },

  methods: {
    loadItem() {
      this.$store
        .dispatch('loadBoxHistoric', this.id)
        .then(r => {
          this.formData = r;
          this.formData.id = this.id;
        })
        .catch(() => {});
    },

    // Permissions
    async checkACL() {
      this.$store
        .dispatch('ACLItems', 17)
        .then(r => {
          if (r.indexOf('pdv-box-historic') !== -1) {
            this.viewPage = true;

            if (r.indexOf('pdv-box-historic-search') !== -1) {
              this.viewPage = true;
            }
            if (r.indexOf('pdv-box-historic-show') !== -1) {
              this.buttonCheck.create = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    openPDF(link) {
      if (link) {
        window.open(link, '_blank');
      } else {
        console.error('Link do PDF não disponível');
        // Aqui você pode adicionar uma lógica para notificar o usuário
        // caso o link não esteja disponível
      }
    }
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    object() {
      return this.$store.state.box_historic.items;
    },

    params() {
      return {
        code: this.formData.code,
        creator_user_id: this.formData.creator_user_id,
        status: this.formData.status
      };
    }
  }
};
</script>

<style scoped>
.spacing-border {
  border: 1px solid #000;
}

.profileImg {
  width: 50px;
  margin: 15px;
  border-radius: 50%;
}

.footer-table {
  display: flex;
  background-color: #000;
  color: #fff;
  width: 100%;
}
</style>
