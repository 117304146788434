<template>
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Consultora:</label>
          <multi-select
            v-model="formData.creator_user_id"
            :options="clients"
            placeholder="Consultora"
          />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Mês:</label>
          <multi-select placeholder="Mês" v-model="formData.month" :options="selectMonthList" />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Ano:</label>
          <multi-select
            placeholder="Ano"
            v-model="formData.year"
            :options="getYearSelect()"
            :searchable="true"
          />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Excel/PDF:</label>
          <multi-select
            placeholder="Excel ou PDF"
            v-model="formData.excel_pdf"
            :options="selectExcelOrPdf"
          />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Banco:</label>
          <multi-select v-model="formData.bank_accounts_id" :options="banks" placeholder="Banco" />
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Tipo de conta:</label>
          <MultiSelect
            v-model="formData.account_types_id"
            :searchable="true"
            @select="selectSubTypeAccount(formData.account_types_id)"
            :options="selectAccountType"
            :noResultsText="noResultsText"
            :noOptionsText="noResultsText"
          />
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Tipo de subconta:</label>
          <MultiSelect
            v-model="formData.account_type_sub_id"
            :searchable="true"
            :options="selectAccountTypeSubList"
            :noResultsText="noResultsText"
            :noOptionsText="noResultsText"
          />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="me-1" @click="submit" name="Gerar" />
        </col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent.vue';
import ColComponent from '@/components/row/ColComponent.vue';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';

export default {
  name: 'FinanceEntrancePreview',
  components: {
    PainelComponent,
    RowComponent,
    ColComponent,
    MultiSelect,
    ButtonSubmit
  },
  created() {
    this.$store.dispatch('changeNamePage', 'Financeiro Entrada (Previsão)');
    this.checkACL();
    this.loadcategoria();
    this.loadSubCategoria();
    this.loadClients();
    this.getAccountBanks();
    this.getAccountsTypes();
  },
  data() {
    // Data atual
    const currentDate = new Date();

    return {
      itemsOne: {
        title: 'Relatório',
        icon: 'list alternate'
      },
      buttonCheck: {
        create: false,
        edit: false
      },
      formData: {
        creator_user_id: '',
        type: '',
        month: currentDate.getMonth() + 1, //Retorna o mês atual
        year: currentDate.getFullYear(), //Retorna o anual atual
        excel_pdf: 2,
        bank_accounts_id: '',
        account_types_id: '',
        account_type_sub_id: ''
      },

      viewPage: false,
      baseUrl: '',

      clients: [],
      banks: [],
      accounts_types: []
    };
  },
  methods: {
    submit() {
      this.$store
        .dispatch('loadFinanceEntrancePreview', { ...this.params })
        .then(r => {
          window.open(r.link, '_blank');
        })
        .catch(() => {});
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 19)
        .then(r => {
          if (r.indexOf('report-all') !== -1) {
            this.viewPage = true;
            if (r.indexOf('report-all-search') !== -1) {
              this.viewPage = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    statusString(value) {
      return parseInt(value) === 1 ? 'Ativo' : 'Inativo';
    },

    loadcategoria(page) {
      this.$store
        .dispatch('loadCategories', { ...this.params, page })
        .then(r => {
          this.categories = r.data.map(category => ({
            label: category.name,
            value: category.id
          }));
        })
        .catch(() => {
          this.searchSubmit = false;
        });
    },

    loadSubCategoria(page) {
      this.$store
        .dispatch('loadSubcategories', { ...this.params, page })
        .then(r => {
          this.subCategories = r.data.map(subCategory => ({
            label: subCategory.name,
            value: subCategory.id
          }));
        })
        .catch(() => {});
    },

    loadClients() {
      this.$store
        .dispatch('getUsersLists')
        .then(r => {
          this.clients = r.map(client => ({
            label: client.name,
            value: client.value,
            icon: client.icon
          }));
        })
        .catch(error => {
          console.error('Erro ao carregar clientes:', error);
        });
    },

    getYearSelect() {
      let dataActual = new Date();
      let year = dataActual.getFullYear() + 2;
      let years = [];
      for (let i = 2024; i <= year; i++) {
        years.push(i);
      }
      return years;
    },

    getAccountBanks() {
      this.$store
        .dispatch('loadAccountBankMultiSelect')
        .then(r => {
          this.banks = r.map(bank => ({
            label: bank.name,
            value: bank.value,
            icon: bank.icon
          }));
        })
        .catch(error => {
          console.error('Erro ao carregar Bancos:', error);
        });
    },

    getAccountsTypes() {
      this.$store
        .dispatch('loadAccountTypeMultiSelect')
        .then(r => {
          this.accounts_types = r.map(account_type => ({
            label: account_type.label,
            value: account_type.value
          }));
        })
        .catch(error => {
          console.error('Erro ao carregar Bancos:', error);
        });
    },

    selectSubTypeAccount(id) {
      this.formData.account_type_sub_id = '';
      this.$store.dispatch('loadAccountTypeSubMultiSelect', id);
    }
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    selectExcelOrPdf() {
      return this.$store.state.var.selectExcelOrPdf;
    },

    selectEntranceExit() {
      return this.$store.state.var.selectEntranceExit;
    },

    selectMonthList() {
      return this.$store.state.var.selectMonthList;
    },

    selectAccountType() {
      return this.$store.state.var.selectAccountList;
    },

    selectAccountTypeSubList() {
      return this.$store.state.var.selectAccountSubList;
    },

    object() {
      return this.$store.state.report_products.items;
    },

    params() {
      return {
        creator_user_id: this.formData.creator_user_id,
        year: this.formData.year,
        month: this.formData.month,
        excel_pdf: this.formData.excel_pdf,
        bank_accounts_id: this.formData.bank_accounts_id,
        account_types_id: this.formData.account_types_id,
        account_type_sub_id: this.formData.account_type_sub_id
      };
    }
  }
};
</script>
